@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.header {
  font-weight: 600;
  font-size: 16px;
  color: $primary;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
