@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.titleWrapper {
  flex: 1;
  min-width: 0;

  .title {
    font-weight: 400;
    font-size: 14px;
  }
}

.valueContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
  }

  .value {
    font-size: 24px;
    font-weight: 600;
    text-align: right;

    &.disabled {
      opacity: 0.5;
    }
  }
}
