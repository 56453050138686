@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.contentWrapper {
  padding: 24px;
  margin: 0 auto;
}

.profileRow {
  margin-bottom: 24px;
  padding: 0 24px;
}

.section {
  margin-bottom: 24px;
  border-radius: 8px;

  .mainTitle {
    font-size: 18px !important;
    margin: 0;
  }
}
