.agreementWrapper {
  white-space: pre-wrap;
  font-size: 14px;
  text-align: justify;

  ul {
    list-style-type: none;
  }
}

.agreementTitle {
  text-align: center;
}

.paragraph {
  text-indent: 30px;
}
