@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.container {
  margin-top: 16px;
  display: flex;
  gap: 8px;

  .text {
    font-size: 14px;
    line-height: 20px;
  }

  a {
    color: $primary;
    text-decoration: none;
  }
}
