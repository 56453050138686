@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
  text-align: center;
  cursor: pointer;
}

.TabsLabels {
  :global(.ant-tabs-tab-btn) {
    font-size: 12px;
    @include breakpoint(desktop) {
      font-size: 14px;
    }
  }
}

.ButtonsRow {
  justify-content: space-between;
  @include breakpoint(desktop) {
    background-color: transparent;
    justify-content: space-between;
  }
}

.ButtonCol {
  display: flex;
  flex-direction: column;
  max-width: 76px;
  align-items: center;
  @include breakpoint(desktop) {
    flex-direction: row;
    max-width: none;
    gap: 8px;
  }
}

.Button {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  @include breakpoint(desktop) {
    background-color: $white;
  }
}

.LoaderRow {
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.LoaderTitle {
  font-weight: 600;
}

.LoaderText {
  font-size: 12px;
  color: $textGrey;
}

.LinkButton {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpoint(desktop) {
    flex-direction: row;
    gap: 8px;
  }
}

.zbBanner {
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  background: url('../../assets/ZBbackground.svg') center/cover no-repeat;

  .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #f0f2f8;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .mobileVersionTitle {
    display: none;
  }

  .desktopVersionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #fff;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 10px 20px 20px 20px;
    height: 100%;

    .textBlock {
      flex: 1;
      color: #fff;

      p {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 8px;
      }
    }

    .disclaimer {
      font-size: 12px;
      opacity: 0.7;
      margin-top: 8px;
    }

    .zbButton {
      font-size: 14px;
      font-weight: 400;
      padding: 24px 30px;
      margin-top: 20px;
      margin-right: 30px;
    }
  }

  @media (max-width: 600px) {
    .mobileHeader {
      display: flex;
      gap: 14px;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .desktopVersionTitle {
      display: none;
    }

    .mobileVersionTitle {
      display: block;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 12px;
      color: #fff;
    }

    .zbButton {
      width: 100%;
      margin: 0 !important;
    }
  }
}
