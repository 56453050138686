@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.historySection {
  margin-top: 24px;

  .historyItemCard {
    margin-bottom: 8px;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
