@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.promoButton {
  margin: 24px 0;
  font-weight: 500;
  font-size: 16px;
  white-space: break-spaces;
  width: 100%;
  height: 52px;
}

.customModal {
  :global {
    .ant-modal-content {
      background: $background;
      padding: 20px 120px;
      border-radius: 12px;
    }

    .ant-modal-header {
      background: transparent;
      border: none;
      padding: 0;
      margin-bottom: 24px;
    }
  }
}

.modalTitle {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: $primary !important;
  text-align: center;
}

.modalContent {
  text-align: center;
}

.mainDescription {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.secondaryDescription {
  font-size: 14px;
  margin-bottom: 32px;
  color: $textGrey;
}

.promoCodeWrapper {
  margin: 24px 0;
}

.promoCode {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 8px;
  background: white;
  border: 1px dashed $greyBorder;
}

.submitButton {
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
}
