@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DefaultModalContainer {
  padding-bottom: 0;
  min-width: 100%;
  top: 0;

  @include breakpoint(desktop) {
    min-width: auto;
  }

  :global {
    .ant-modal-close {
      width: 25px;
      height: 25px;
      top: 10px;
      right: 10px;
      margin: 0;
      &:active,
      &:hover {
        background-color: transparent;
      }
    }

    .ant-modal-content {
      margin: 0 auto;
      border-radius: 14px 14px 0 0;
      width: 100%;
      min-height: 300px;
      padding: 14px;

      @include breakpoint(desktop) {
        overflow: hidden;
        margin-top: 40px;
        border-radius: 20px;
        width: 620px;
        max-height: calc(100vh - 80px);
      }
    }

    .ant-modal {
      @include breakpoint(desktop) {
        width: 620px;
      }
    }

    .ant-modal-body {
      overflow-y: auto;
      max-height: calc(100vh - 80px);
    }

    .ant-modal-close-x {
      @include breakpoint(desktop) {
        svg {
          fill: transparent;
          transform: scale(1.34);
        }
        path {
          stroke-width: 1;
        }
      }
    }
  }

  :global {
    .ant-modal-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include breakpoint(desktop, max) {
        align-items: flex-end;
      }
    }

    .ant-modal {
      @include breakpoint(desktop) {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  :global {
    .UploadDocWide {
      @include breakpoint(desktop) {
        .ant-modal-content {
          width: 720px;
        }
      }
    }
  }
}
