@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DocumentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
}

.DocumentContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.TitleRow {
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  @include breakpoint(desktop) {
    justify-content: space-between;
  }
}

.Subrow {
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
}

.Divider {
  margin: 0;
}

.SmallText {
  font-size: 12px;
}

.Title {
  font-size: 16px;
  font-weight: 600;
  @include breakpoint(desktop) {
    max-width: 298px;
  }
}

.RoundedLabel {
  font-size: 12px;
  border-radius: 40px;
  border: 1px solid $buttonBorder;
  padding: 4px 10px;
}

.GreenBorder {
  border-color: $green;
}

.RedBorder {
  border-color: $red;
  path {
    stroke: $red;
  }
}

.Icon {
  margin-right: 6px;
}

.IconCol {
  width: 38px;
  height: 38px;
  background-color: $background;
  padding: 5px 7px;
  display: flex;
  border-radius: 10px;

  :global(.ant-image) {
    width: 100%;
    align-self: center;
  }

  :global(.ant-image-img) {
    vertical-align: baseline;
  }
}

.DocumentButton {
  height: 36px;
  width: 100%;

  &Disabled {
    filter: grayscale(1);
  }
}

.DocumentIconButton {
  height: 36px;
  padding: 0 5px;
  box-shadow: none;
  background-color: transparent !important;
}

.AccessMobile {
  display: flex;
  @include breakpoint(desktop) {
    display: none;
  }
}

.AccessDesktop {
  display: none;
  @include breakpoint(desktop) {
    display: flex;
  }
}

.ShowButtonMobile {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  @include breakpoint(desktop) {
    display: none;
  }
}

.ShowButtonDesktop {
  display: none;

  @include breakpoint(desktop) {
    display: flex;
    align-self: flex-start;
  }
}

.MobileButtonsContainer {
  flex-wrap: nowrap;
}

.tooltip {
  :global {
    .ant-tooltip-inner {
      background: $primary;
    }
  }

  :global(.ant-tooltip-arrow::before) {
    background: $primary;
  }
}
