@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.agreementModalContainer {
  padding-top: 96px;

  @include breakpoint(desktop) {
    padding-top: 0;
  }

  :global(.ant-modal-content) {
    padding: 26px;

    @include breakpoint(desktop) {
      max-height: none;
    }
  }
}
