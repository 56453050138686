@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.StatsMedcardContainer {
  gap: 30px;

  @include breakpoint(desktop) {
    gap: 21px;
    flex-flow: nowrap;
  }
}

.MainWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background: transparent;
  gap: 30px;
  @include breakpoint(desktop) {
    gap: 50px;
    max-width: 850px;
  }
}

.Title.Title {
  margin: 0;
  font-size: 18px;
  color: $primaryDark;
}

.Col {
  gap: 15px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

  @include breakpoint(desktop) {
    width: 50%;
  }
}

.BottomRow {
  gap: 30px;
  flex-wrap: wrap;
  @include breakpoint(desktop) {
    flex-wrap: nowrap;
    gap: 20px;
  }
}

.ColRow {
  align-items: end;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}

.Link {
  margin-bottom: 2px;
}

.Link.Link {
  color: $primary;
  font-size: 12px;
}

.ServicesRow {
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  background: none;
  width: auto;
  padding: 0;
  box-shadow: none;

  @include breakpoint(desktop) {
    width: 100%;
    overflow: hidden;
    background: $white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    padding: 20px;
  }
}

.MobileBlocksRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  @include breakpoint(desktop) {
    display: none;
  }
}

.ImportantBlockContainer {
  position: relative;
  gap: 25px;
}

.Arrows {
  position: absolute;
  right: 18px;
  top: 21px;
}

.NameRow {
  align-items: center;
  justify-content: space-between;

  @include breakpoint(desktop) {
    padding-right: 0;
  }
}

.NameCol {
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    flex-shrink: 0;
  }
}

.Name {
  font-size: 16px;
  font-weight: 600;
  color: $primaryDark;
  text-overflow: ellipsis;
  max-width: 330px;
  overflow: hidden;
  white-space: nowrap;
  @include breakpoint(desktop) {
    font-size: 30px;
  }
}

.SpecialSearch {
  display: none;
  @include breakpoint(desktop) {
    display: flex;
    min-width: 450px;
    min-height: 44px;
    span {
      border-radius: 14px;
    }
  }
}

.Search {
  @include breakpoint(desktop) {
    min-width: 450px;
  }
}

.SearchBackground {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 6;
}

.SearchRow {
  position: absolute;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  z-index: 7;
  max-width: 347px;

  @include breakpoint(desktop) {
    padding: 15px;
    max-width: 850px;
  }
}

.AgeToggleContainer {
  width: 100%;
  :global(.ant-tabs-tab) {
    width: 50%;
  }
}

.SearchWrapper {
  width: 100%;
  border-radius: 14px;
  border: 1px solid $border;
  padding: 12px;

  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.SearchFiltersRow {
  width: 100%;
  padding: 0;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  @include breakpoint(desktop) {
    padding: 20px 30px;
  }
}

.RegionRow {
  gap: 8px;
}

.RegionChoice {
  font-size: 14px;
  font-weight: 400;
  @include breakpoint(desktop) {
    font-size: 18px;
    font-weight: 600;
  }
}

.MobileSearchCol {
  display: flex;
  gap: 14px;
  align-items: center;
  @include breakpoint(desktop) {
    display: none;
  }
}

.SearchIcon {
  min-width: 20px;
  min-height: 20px;
  margin-top: 3px;
  path {
    stroke: $textPrimary;
  }
}

.NameProfileRowContainer {
  flex-direction: column;
  gap: 20px;
  margin-bottom: -16px;

  @include breakpoint(desktop) {
    margin-bottom: -10px;
  }
}

.MobileHide {
  display: none;
  @include breakpoint(desktop) {
    display: block;
  }
}

.MobileShow {
  display: block;

  @include breakpoint(desktop) {
    display: none;
  }
}

.CloseSearchButton {
  background: none;
  box-shadow: none;
  margin-left: auto;
  margin-bottom: -10px;

  &:hover {
    background-color: $white;
  }

  @include breakpoint(desktop) {
    margin-bottom: -20px;
  }
}

.MilaIcon {
  max-width: 35px;
}

.ForceHidden {
  display: none !important;
}
