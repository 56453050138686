@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ImportantRow {
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;
}

.ImportantCol {
  min-width: 270px;
  height: 210px;
  padding: 20px;
  border-radius: 14px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
}

.ImportantCol1 {
  :global(.ant-image) {
    width: 79px;
    height: 91px;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

.Title2 {
  font-size: 18px;
  color: $white;
  font-weight: 600;
  max-width: 160px;
  line-height: 24px;
}

.ShortcutText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 18px;
}

.ImportantCol3 {
  article {
    color: $textPrimary;
  }

  :global(.ant-image) {
    width: 105px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 25px;
  }
}

.Title {
  font-size: 18px;
  color: $white;
  font-weight: 600;
}

.Subtitle {
  font-size: 12px;
  color: $white;
  text-transform: uppercase;
}

.Text {
  font-size: 12px;
  color: $white;
  max-width: 150px;
  opacity: 0.8;
}

.Arrows {
  position: absolute;
  right: 0;
  top: 0;
}

.ImportantColShareMedcard {
  overflow: hidden;

  :global(.ant-image) {
    width: 110px;
    height: 170px;
    position: absolute;
    right: 0;
    bottom: -8px;
  }

  :global(.ant-typography) {
    color: $textPrimary;
  }
}

.ImportantColUpdateMedcardWL {
  :global(.ant-typography) {
    color: $buttonBorder;
  }
}

.ImportantColChildrenMedcard {
  overflow: hidden;

  :global(.ant-image) {
    width: 110px;
    height: 170px;
    position: absolute;
    right: 0;
    bottom: -24px;
  }
}

.ImportantColPharm {
  overflow: hidden;
  
  .Title2 {
    max-width: 180px;
  }

  :global(.ant-image) {
    width: 120px;
    height: 140px;
    position: absolute;
    right: -30px;
    bottom: 10px;
    rotate: -25deg;
  }
}

.TextBlock {
  flex-direction: column;
}

.Avatar.Avatar.Avatar {
  width: 30px;
  height: 30px;
}

.AvatarText {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.24px;
}

.AvatarRow {
  gap: 8px;
  align-items: center;
}

.AddClinicModal :global(.ant-modal-content) {
  background-color: $background;
}

.ImportantColShortText {
  .Text {
    max-width: 140px;
  }

  justify-content: space-evenly;
}

.ModalContainer {
  :global(.ant-modal-content) {
    max-height: 1004px;
    background: $background;
  }
}

.MedcardUpdateCard {
  gap: 12px;
}

.MedcardUpdateCardIcon {
  :global(.ant-image) {
    width: 96px;
    height: 93px;
    position: absolute;
    right: 15px;
    bottom: 80px;
  }
}

.AppointmentUpdateIcon,
.AddClinicCardIcon {
  :global(.ant-image) {
    width: 110px;
    height: 170px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.HiddenBlock {
  display: none;
}

.Share {
  position: absolute;
  bottom: 112px;
  left: 50%;
  transform: translateX(-50%);
  background: $white;
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid $border;
  text-align: left;

  & :global {
    .ya-share2__title {
      font-size: 14px;
    }
  }
}

.regionIcon {
  :global(.ant-image) {
    width: 82px;
    position: absolute;
    right: 10px;
    bottom: 40px;
  }
}