@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.container {
  width: 100%;
  padding: 8px 0;

  .coinIcon {
    width: 10px;
    height: 10px;
  }

  .amount {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;

    &.red {
      color: $red;
    }
  }

  .details {
    font-size: 12px;
    line-height: 1.3;
    margin-top: 4px;
    display: block;
  }

  .description {
    font-size: 14px;
    line-height: 1.5;
  }
}
