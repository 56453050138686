@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.viewerContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  :global(.rpv-core__page-layer) {
    margin: 0 auto;
  }

  :global(.rpv-core__text-layer) {
    display: none;
  }
}

.toolbarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.customToolbar {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $background;
  padding: 10px 30px;
}

.pageNavigation {
  display: flex;
  align-items: center;
  gap: 8px;
  transform: translateX(100%);

  &Button {
    border-radius: 50%;
    border: none;
  }
}

.zoomSection {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pdfContainer {
  width: 100%;
  height: 100%;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.share {
  color: $primary;
  display: flex;
  align-items: center;
  gap: 8px;
}

.contentContainer {
  flex: 1;
  overflow: auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewerContent {
  position: relative;
  height: 100%;
}

.scaleWrapper {
  transition: transform 0.2s ease-in-out;
  display: inline-block;
  width: 640px;
}

.mobileOnly {
  display: none;
}

.desktopOnly {
  display: flex;
  gap: 8px;
}

@media (max-width: 768px) {
  .pageNavigation {
    transform: none;
  }

  .customToolbar {
    padding: 10px;
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }

  .viewerContent {
    width: 100%;
  }

  .scaleWrapper {
    width: 100% !important;
    max-width: 100%;
    transform-origin: center top;
    touch-action: pan-y;
  }

  .contentContainer {
    touch-action: pan-y;
    padding: 0;
    align-items: flex-start;
  }

  .pdfContainer {
    :global(.rpv-core__page-layer) {
      width: 100% !important;
      min-width: 100%;
      transform: scale(1) !important;
    }
  }

  :global {
    .rpv-core__page-layer {
      width: 100% !important;
      max-width: 100% !important;
      min-width: auto !important;
    }

    .rpv-core__inner-page {
      width: 100% !important;
      box-sizing: border-box;
    }

    .rpv-core__page {
      width: 100% !important;
      height: auto !important;
      min-height: auto !important;
    }

    .rpv-core__canvas-layer {
      width: 100% !important;
      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
